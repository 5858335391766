<template>
  <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible" width="1000px" top="15vh"
    @close="onDialogClose">
    <div v-loading="contentLoading">
      <el-form ref="formValidate" :label-width="$l('120px', '100px')" :model="maintWorkOrder" :rules="ruleValidate">
        <div class="vm-separate">
          <el-form-item :label="$t('elevator.no')" prop="elevatorNo">
            <el-input v-model="maintWorkOrder.elevatorNo" :placeholder="$t('common.pleaseSelect')" readonly>
              <el-button slot="append" icon="el-icon-search" @click="$refs.selectElevator.open()"></el-button>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('maintWorkOrder.maintDate')" prop="maintDate">
            <el-date-picker v-model="maintWorkOrder.maintDate" type="date" style="width: 100%"
              :placeholder="$t('yearCheck.date')" value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"></el-date-picker>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevator.maintWorker') + '1'" prop="maintainerName1">
            <el-input v-model="maintWorkOrder.maintainerName1" :placeholder="$t('maintWorkOrder.selectElevator')"
              readonly>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('elevator.maintWorker') + '2'" prop="maintainerName2">
            <el-input v-model="maintWorkOrder.maintainerName2" :placeholder="$t('maintWorkOrder.selectElevator')"
              readonly>
            </el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('maintWorkOrder.maintType')" prop="maintType">
            <el-select v-model="maintWorkOrder.maintType" value-key="id" :placeholder="$t('common.pleaseSelect')"
              style="width: 78%" clearable>
              <el-option v-for="item in maintTypeList" :key="item.id" :label="item.maintTypeName" :value="item.id"
                @click.native="handleMaintType(item)">
              </el-option>
            </el-select>
            <el-button style="width: 20%; float: right" type="text" @click="handleMaintTypeItem">
              {{ $t("maintWorkOrder.checkMaintItem") }}
            </el-button>
          </el-form-item>
          <el-form-item :label="$t('maintWorkOrder.remark')" prop="remark">
            <el-input v-model.trim="maintWorkOrder.remark" type="textarea" :rows="5"
              :placeholder="$t('common.pleaseEnter')" maxlength="200">
            </el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">{{
        $t("common.save") }}</el-button>
    </span>
    <elevator-select ref="selectElevator" @select="onSelectElevator"></elevator-select>
    <maint-type-item ref="listMaintTypeItem"></maint-type-item>
  </el-dialog>
</template>
<script>
import ElevatorSelect from "../elevator/ElevatorSelect.vue";
import MaintTypeItem from "@/views/newMaintWorkOrder/maintSetting/maintTypeItem";

const moduleName = "maintenanceOrder";
export default {
  components: { ElevatorSelect, MaintTypeItem },
  data() {
    return {
      contentLoading: false,
      dialogVisible: false,
      submitLoading: false,
      saveDisabled: false,
      maintTypeList: [],
      maintWorkOrder: {
        id: 0,
        elevatorId: "",
        elevatorNo: "",
        maintDate: "",
        maintComId: "",
        maintainerId1: "",
        maintainerName1: "",
        maintainerId2: "",
        maintainerName2: "",
        maintType: "",
        maintTypeName: "",
        remark: "",
        orgId: "",
      },
      ruleValidate: {
        maintDate: [
          { required: true, message: this.$t("maintWorkOrder.tip.date"), trigger: "blur" },
        ],
        elevatorNo: [
          { required: true, message: this.$t("maintWorkOrder.tip.elevatorNo"), trigger: "blur" },
        ],
        maintType: [
          { required: true, message: this.$t("maintWorkOrder.tip.maintType"), trigger: "blur" },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 3600 * 1000 * 24;
        },
      },
    };
  },
  computed: {
    title() {
      return (this.maintWorkOrder.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("route.maintenanceWorkOrder");
    },
  },
  methods: {
    open(id) {
      this.dialogVisible = true;
      this.maintWorkOrder.id = id;
      if (id > 0) {
        this.getData();
      }
      this.getMaintTypeList();
    },
    getData() {
      this.contentLoading = true;
      this.saveDisabled = true;
      this.$api.getById(moduleName, this.maintWorkOrder.id).then(res => {
        this.maintWorkOrder = res.data;
        this.contentLoading = false;
        this.saveDisabled = false;
      }).catch((error) => {
        this.contentLoading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
        }
      });
    },
    getMaintTypeList() {
      this.$api.getData("maintType/all", null).then(res => {
        this.maintTypeList = res.data;
      }).catch(error => {
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    onDialogClose() {
      this.saveDisabled = false;
      this.$refs.formValidate.resetFields();
      this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
    },
    handleSubmit() {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          this.$api.save(moduleName, this.maintWorkOrder).then(() => {
            this.submitLoading = false;
            this.dialogVisible = false;
            this.$emit("save-success");
            this.$message.success(this.$t("common.tip.saveSuccess"));
          }).catch(error => {
            this.submitLoading = false;
            this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
          });
        } else {
          this.$message.error(this.$t("common.tip.submitError") + "!");
        }
      });
    },
    onSelectElevator(row) {
      this.maintWorkOrder.elevatorId = row.id;
      this.maintWorkOrder.elevatorNo = row.no;
      this.maintWorkOrder.maintComId = row.maintComId;
      this.maintWorkOrder.maintainerId1 = row.maintEmpId;
      this.maintWorkOrder.maintainerName1 = row.maintEmpName;
      this.maintWorkOrder.maintainerId2 = row.maintEmpId1;
      this.maintWorkOrder.maintainerName2 = row.maintEmpName1;
      this.maintWorkOrder.orgId = row.orgId;
    },
    handleMaintType(item) {
      this.maintWorkOrder.maintType = item.id;
      this.maintWorkOrder.maintTypeName = item.maintTypeName;
    },
    handleMaintTypeItem() {
      if (this.maintWorkOrder.maintType) {
        this.$refs.listMaintTypeItem.open(this.maintWorkOrder.maintType);
      } else {
        this.$message.warning(this.$t("maintWorkOrder.tip.emptyMaintType") + "!");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
