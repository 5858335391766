<template>
  <el-dialog :title="$t('maintWorkOrder.maintPlan')" :visible.sync="dialogVisible" width="1100px" top="2vh">
    <el-table v-loading="loading" :data="maintDateList" stripe border>
      <el-table-column type="index" :label="$t('common.index')" width="45px" align="center"></el-table-column>
      <el-table-column prop="maintDate" :label="$t('maintWorkOrder.maintDate')"></el-table-column>
      <el-table-column prop="maintTypeName" :label="$t('maintWorkOrder.maintTypeName')"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      dialogVisible: false,
      maintDateList: [],
    };
  },
  methods: {
    open(id) {
      this.dialogVisible = true;
      this.getData(id);
    },
    getData(id) {
      this.$api.getById("maintPlan/elevator", id).then(res => {
        this.maintDateList = res.data;
        this.loading = false;
      }).catch((error) => {
        if (error.response) {
          this.loading = false;
          this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>